import React from 'react';
import AnimatedButton from '../components/AnimatedButton';
import { useTranslation } from 'react-i18next';

const PublicationCard = ({ publicationKey, imageUrl, link }) => {
  const { t } = useTranslation();

  const title = t(`${publicationKey}.title`);
  const journal = t(`${publicationKey}.journal`);
  const description = t(`${publicationKey}.description`);
  const authors = t(`${publicationKey}.authors`);
  const date = t(`${publicationKey}.date`);

  return (
    <div className="flex items-stretch justify-between gap-4 bg-[#e8f5e9] p-4 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300">
      <div className="flex flex-col gap-2 flex-[2_2_0px]">
        <p className="text-[#4a7f4d] text-sm font-normal leading-normal">{journal}</p>
        <p className="text-[#2e7d32] text-base font-bold leading-tight">{title}</p>
        <p className="text-[#4a7f4d] text-sm font-normal leading-normal">{description}</p>
        <p className="text-[#388e3c] text-sm font-medium">{t('authors')}: {authors}</p>
        <div className="flex justify-between items-center mt-4">
          <p className="text-[#4a7f4d] text-sm font-normal">{date}</p>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <AnimatedButton className="min-w-[120px]">
              {t('viewPublication')}
            </AnimatedButton>
          </a>
        </div>
      </div>
      <div
        className="w-48 bg-center bg-no-repeat aspect-video bg-cover rounded-xl flex-1"
        style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'contain' }}
      ></div>
    </div>
  );
};

export default PublicationCard;
