import React from 'react';

const AnimatedButton = ({ children, className }) => {
  return (
    <button
      className={`relative flex items-center justify-center overflow-hidden rounded-xl h-12 px-5 bg-[#36c26c] text-white text-base font-bold leading-normal tracking-[0.015em] transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg active:scale-95 active:bg-[#2e9e5a] ${className}`}
    >
      <span className="truncate">{children}</span>
      <div className="absolute inset-0 bg-white opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-10"></div>
    </button>
  );
};

export default AnimatedButton;
