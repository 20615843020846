import React from 'react';
import { Link } from 'react-router-dom';
import PhotoCard from '../components/PhotoCard';
import PublicationCard from '../components/PublicationCard';
import SectionHeader from '../components/SectionHeader';
import AnimatedButton from '../components/AnimatedButton';
import VideoPlayer from '../components/VideoPlayer';
import { useTranslation } from 'react-i18next';

// Main component
const MainPage = () => {

  const { t } = useTranslation();

  const publications = [
    {
      key: "publication1",
      imageUrl: require('../assets/PublicationPhotos/publ1.jpeg'),
      link: "https://dergipark.org.tr/tr/journal/1878/article/1529025"
    }
    // Add more publications here
  ];

  const photos = [
    require('../assets/ProjectPhotos/photo1.jpg'),
    require('../assets/ProjectPhotos/photo2.jpg'),
    require('../assets/ProjectPhotos/photo3.jpg'),
  ];

  return (
    <div className="relative flex size-full min-h-screen flex-col bg-gray-100 group/design-root overflow-x-hidden bg-gray-100" style={{ fontFamily: 'Lexend, "Noto Sans", sans-serif' }}>
      <div className="layout-container flex h-full grow flex-col">
        <div className="px-40 flex flex-1 justify-center py-5">
          <div className="layout-content-container flex flex-col max-w-[1280px] flex-1">
            <div className="flex flex-col md:flex-row items-start gap-8 bg-gray-100 rounded-xl p-4 md:p-8">
              <div className="flex-shrink-0 md:w-[640px] md:h-[360px] mb-4 md:mb-0">
                <VideoPlayer videoUrl="https://www.youtube.com/embed/NleaSZpHses" className="rounded-xl shadow-lg" />
              </div>
              <div className="flex flex-col justify-center text-center md:text-left md:ml-8 mb-8">
                <h2 className="text-2xl font-bold text-green-700 mb-4">{t('whatWeDo')}</h2>
                <p className="text-lg text-gray-700 mb-6">
                  {t('projectSummary')}
                </p>
                <Link to='/about'>
                  <button className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 lg:h-12 lg:px-5 bg-[#36c26c] text-white text-sm font-bold leading-normal tracking-[0.015em] lg:text-base lg:font-bold lg:leading-normal lg:tracking-[0.015em] transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg active:scale-95 active:bg-[#2e9e5a]">
                    <span className="truncate">{t('aboutUs')}</span>
                  </button>
                </Link>
              </div>
            </div>

            <SectionHeader title={t('recentPublication')} />
            <div className="p-4">
              {publications.map((pub, index) => (
                <div key={index} className="pb-4">
                  <PublicationCard
                    key={index}
                    publicationKey={pub.key}
                    imageUrl={pub.imageUrl}
                    link={pub.link}
                  />
                </div>
              ))}
            </div>
            <div className="flex px-4 py-3 justify-center">
              <Link to='/publications'><AnimatedButton>{t('learnMore')}</AnimatedButton></Link>
            </div>
            <SectionHeader title={t('photos')} />
            <div className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
              {photos.map((url, index) => (
                <PhotoCard key={index} imageUrl={url} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
