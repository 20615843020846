// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector) // Tarayıcı dilini otomatik algılamak için
  .use(initReactI18next) // react-i18next modülünü başlatır
  .init({
    resources: {
      en: {
        translation: {
          publication1: {
            title: "CROP DISEASES USING AGRICULTURAL SENSOR DATA LSTM-BASED DEEP LEARNING MODEL FOR EARLY DETECTION",
            journal: "DergiPark",
            description: "Reliable and timely identification of plant diseases is a crucial challenge in modern agriculture. Traditional methods rely on manual observation of visible symptoms. Visible symptoms tend to appear in the middle or late stages of infection, which increases the likelihood of spread or yield reduction. In this study, an LSTM-based deep learning model for early detection of plant diseases is proposed. This model is able to perform anomaly detection by analyzing time series data from agricultural sensors, thereby identifying plant diseases before their visual symptoms appear. Metrics such as accuracy, classification report and confusion matrix were used to evaluate the performance of the model. The results obtained are quite successful; the model achieved 99.95% accuracy and showed high success in anomaly detection. As a result of the study, with the early detection of plant diseases, cost-reducing measures can be taken at the highest level with minimum pesticides, and humans and the environment will be protected at the maximum level.",
            authors: "Elif Genç, Cem Bağlum, Osman Çağlar, Yusuf Kartal, Erol Seke, Kemal Özkan",
            date: "Not Yet Published"
          },
          download: "Download Data",
          downy: {
            title: "Downy Mildew",
            description: "Downy Mildew is a fungal disease caused by the pathogen Pseudoperonospora cubensis, which mainly affects cucumbers and other cucurbit plants. This disease often presents as yellow, angular spots on the upper side of the leaves, typically bordered by veins. As the infection progresses, these spots may turn brown and dry out, causing the leaves to wither and die. The disease thrives in humid, cool conditions, and the fungus spreads rapidly through water splash or air. Severe infestations can lead to a significant reduction in crop yield, affecting both the quality and quantity of produce.",
          },    
          powdery: {
            title: "Powdery Mildew",
            description: "Powdery Mildew is a fungal disease caused by various species of fungi from the family Erysiphaceae, including Golovinomyces cichoracearum and Podosphaera xanthii. It is identifiable by white, powdery spots that form on the leaves, stems, and sometimes even fruits. These spots often start as small circular patches but can spread and cover entire leaves, disrupting photosynthesis and weakening the plant. Powdery Mildew thrives in warm, dry environments but requires moderate humidity to germinate and spread. Severe infections may stunt plant growth, reduce fruit quality, and affect overall yield.",
          },
          wilt: {
            title: "Bacterial Wilt",
            description: "Bacterial wilt is caused by the bacterium Erwinia tracheiphila. It affects the plant's vascular system, spreading from the roots to stems and leaves. Infected plants begin to wilt rapidly, leaves turn yellow, and the plant eventually dries out completely. The bacterium is often transmitted by insects and blocks the plant's water-conducting system, leading to its death. Early detection and reducing insect populations are crucial for managing this disease.",
          },
          angularspot: {
            title: "Angular Leaf Spot",
            description: "Angular leaf spot is commonly caused by the bacterium Pseudomonas syringae pv. lachrymans. The disease appears as angular, brown, or yellow spots on leaves, often surrounded by a water-soaked halo. Infected leaves dry out and drop from the plant. The disease spreads rapidly under humid conditions, reducing the plant's photosynthetic capacity and ultimately lowering yield.",
          },
          mosaic: {
            title: "Cucumber Mosaic Virus",
            description: "Cucumber mosaic virus (CMV) is a viral disease caused by the Cucumber mosaic virus. It manifests as mosaic patterns, leaf deformities, and stunted growth. The virus is primarily transmitted by insects (especially aphids) and can also spread through infected seeds. Severe infections can halt plant growth and drastically reduce fruit production.",
          },
          mottle: {
            title: "Cucumber Green Mottle Mosaic Virus",
            description: "Cucumber green mottle mosaic virus (CGMMV) causes light and dark green mosaic patterns on leaves. The virus can also lead to discoloration and deformities in fruits. It is commonly spread through infected seeds or contaminated irrigation water. Severe infections weaken the plant overall and reduce fruit quality.",
          },
          bacterialDiseases: "Bacterial Diseases",
          fungalDiseases: "Fungal Diseases",
          viralDiseases: "Viral Diseases",
          photoData: {
            title: "Photos",
            description: "This file contains plant photos.",
          },
          sensorData: {
            title: "Sensor Data",
            description: "This file contains sensor data as csv file.",
          },
          whatWeDo: "What We Do?",
          projectSummary: "The reliable and timely identification of plant diseases is one of the major challenges in modern agriculture. Traditional methods often rely on symptoms in the later stages. Artificial intelligence, through image processing and machine learning, can detect disease symptoms at early stages, providing real-time data through sensors and IoT devices, enabling farmers to take proactive measures. These methods increase efficiency while minimizing environmental impacts.This project focuses on developing and applying AI models trained with data collected through IoT devices and sensors for early diagnosis and disease management.",
          authors: "Authors",
          welcome: "Sensor Data",
          mainPage: "Main Page",
          ourTeam: "Our Team",
          publications: "Publications",
          diseases: "Diseases",
          about: "About",
          data: "Data",
          university: "Eskisehir Osmangazi University",
          avgSalt: "Average Salt",
          avgNitrogen: "Average Nitrogen",
          avgHumidity: "Average Humidity",
          aboutUs: "About Us",
          learnMore: "Learn More",
          viewPublication: "View Publication",
          recentPublication: "Recent Publications",
          photos: "Photos",
          meet: "Meet The Team",
          projectManager: "Project Manager",
          researchers: "Researchers",
          scholarships: "Scholarship Students",
          linkedin: "Linkedin Profile",
          researcher: "Researcher",
          scholarship: "Scholarship Student",
          voluntary:"Voluntary Contributor",
          undergraduateStudent: "Undergraduate Student",
          graduateStudent: "Graduate Student",
          prof: "Professor",
          assistantProf: "Assistant Professor",
          associateProf: "Associate Professor",
          agriculturalEng: "Department of Agriculture",
          computerEng:"Department of Computer Engineering",
          electricalEng: "Department of Electrical and Electronics Engineering",
          department: "Department",
          publication: "Publicatinos",
          aboutProject: "About Our Project",
          projectTitle:"Project Title",
          project: "Early Diagnosis and Monitoring of Certain Diseases and Pests in Cucumber Cultivation under Greenhouse Conditions Using Artificial Intelligence",
          p1:"Reliable and timely identification of plant diseases is one of the major challenges faced in modern agriculture. Traditional methods often rely on manual observation of visible symptoms. However, these symptoms typically appear in the middle or late stages of infection, making it difficult to intervene before the disease spreads in the plant or causes yield loss. Therefore, more early and precise detection methods are needed to reduce the risk of spread and prevent crop losses.",
          p2:"Artificial intelligence technologies can play a transformative role in agriculture at this point. Image processing and machine learning algorithms can analyze various images of plants to detect disease signs even before visible symptoms appear. These algorithms can make high-accuracy predictions by being trained on large datasets, considering different stages of diseases, climatic conditions, and plant species.",
          p3:"Moreover, AI-powered sensors and IoT devices can analyze real-time data collected from fields to provide instant alerts. This allows farmers to continuously monitor every plant in their fields and take proactive measures against diseases. This technology not only speeds up disease detection but also optimizes pesticide use, minimizing environmental impacts.",
          p4:"As a result, modern agricultural methods integrated with artificial intelligence have great potential for early disease detection and management. These technologies can enhance productivity while contributing to the widespread adoption of sustainable agricultural practices.",
          p5:"This project involves research on developing and adapting AI models for early diagnosis and disease management using data collected from IoT devices and sensors.",
          note:"**This project is supported by the Scientific and Technological Research Council of Turkey (TÜBİTAK) under project number 123O928. We express our gratitude to TÜBİTAK for their support.**",
          startDate: "Start Date",
          endDate: "End Date",
          update: "Update",
          sector1Data: "Sector 1 Data",
          sector1NoData: "No data found for Sector 1 in the selected date range.",
          sector2Data: "Sector 2 Data",
          sector2NoData: "No data found for Sector 2 in the selected date range.",
          temperature: "Temperature",
          light: "Light x10",
          humidity: "Humidity"
        },
      },
      tr: {
        translation: {
          publication1: {
            title: "TARIMSAL SENSÖR VERİLERİ KULLANILARAK BİTKİ HASTALIKLARININ ERKEN TESPİTİ İÇİN LSTM TABANLI DERİN ÖĞRENME MODELİ",
            journal: "DergiPark",
            description: "Bitki hastalıklarının güvenilir ve zamanında tanımlanması modern tarımda çok önemli bir zorluktur. Geleneksel yöntemler gözle görülür semptomların manuel olarak gözlemlenmesine dayanır. Görünür semptomlar, enfeksiyonun orta veya geç aşamalarında ortaya çıkma eğilimindedir; bu da yayılma veya verim azalması olasılığını artırır. Bu çalışmada, bitki hastalıklarının erken tespiti için LSTM tabanlı bir derin öğrenme modeli önerilmiştir. Bu model, tarımsal sensörlerden gelen zaman serisi verilerini analiz ederek anomali tespiti yapabilmekte, bu sayede bitki hastalıkların görsel belirtileri ortaya çıkmadan hastalıklı olduklarını söylemektedir. Modelin performansını değerlendirmek için doğruluk, sınıflandırma raporu, karışıklık matrisi gibi metrikler kullanılmıştır. Elde edilen sonuçlar oldukça başarılı; model %99.95 doğruluk sağlamış ve anomali tespiti konusunda yüksek başarı göstermiştir. Yapılan çalışma sonucunda bitki hastalıkların erken tespiti ile minimum zirai ilaçlama ile maliyet düşürücü tedbirler en üst seviyede alınabilecek insan ve çevre maksimum seviyede korunmuş olacaktır.",
            authors: "Elif Genç, Cem Bağlum, Osman Çağlar, Yusuf Kartal, Erol Seke, Kemal Özkan",
            date: "Henüz Yayınlanmadı"
          },
          download: "Verileri İndir",
          downy: {
            title: "Tüylü Küf",
            description: "Tüylü küf (Downy Mildew), özellikle salatalık ve diğer kabakgiller üzerinde görülen, Pseudoperonospora cubensis adlı patojen tarafından kaynaklanan bir mantar hastalığıdır. Genellikle yaprakların üst yüzeyinde, damarlarla sınırlanmış sarı, açısal lekeler olarak kendini gösterir. Enfeksiyon ilerledikçe bu lekeler kahverengiye döner ve kuruyarak yaprakların solmasına ve ölmesine neden olur. Hastalık, serin ve nemli koşullarda hızla yayılır ve su sıçraması veya hava yoluyla bulaşabilir. Şiddetli enfeksiyonlar, mahsul verimini önemli ölçüde düşürerek hem kaliteyi hem de miktarı olumsuz etkileyebilir.",
          },    
          powdery: {
            title: "Unlu Küf Hastalığı",
            description: "Unlu Küf Hastalığı (Powdery Mildew), Erysiphaceae ailesine ait, Golovinomyces cichoracearum ve Podosphaera xanthii gibi çeşitli mantar türleri tarafından meydana gelen bir mantar hastalığıdır. Yapraklar, saplar ve bazen de meyveler üzerinde beyaz, unlu lekeler olarak kolayca fark edilebilir. Bu lekeler genellikle küçük, dairesel yamalar halinde başlar, ancak hızla büyüyerek tüm yaprağı kaplayabilir ve fotosentezi engelleyerek bitkiyi zayıflatır. Unlu Küf, ılık ve kuru ortamları sever, ancak çimlenip yayılması için orta seviyede nem gerektirir. Şiddetli enfeksiyonlar, bitki büyümesini yavaşlatabilir, meyve kalitesini düşürebilir ve toplam verimi olumsuz etkileyebilir.",
          },
          wilt: {
            title: "Bacterial Wilt",
            description: "Bakteriyel solgunluk (Bacterial Wilt), Erwinia tracheiphila adlı bakterinin neden olduğu bir hastalıktır. Bitkinin damar sistemini etkiler, köklerden başlayarak sap ve yapraklara kadar yayılır. Enfekte bitkiler hızla solmaya başlar, yapraklar sararır ve sonunda tamamen kurur. Bakteri, genellikle böcekler tarafından taşınır ve bitki su taşıma sistemini tıkayarak ölümüne neden olur. Hastalık kontrolü için erken teşhis ve böcek popülasyonunun azaltılması kritik öneme sahiptir.",
          },
          angularspot: {
            title: "Açısal Yaprak Lekesi",
            description: "Açısal yaprak lekesi (Angular Leaf Spot), genellikle Pseudomonas syringae pv. lachrymans bakterisi tarafından oluşturulur. Hastalık, yapraklarda köşeli, kahverengi veya sarı lekeler şeklinde belirir. Lekelerin çevresi genellikle suyla ıslanmış bir görüntüye sahiptir. Enfekte yapraklar kuruyarak bitkiden dökülür ve hastalık nemli koşullarda hızla yayılır. Yaprak kaybı nedeniyle bitki fotosentez kapasitesini kaybeder ve ürün verimi düşer.",
          },
          mosaic: {
            title: "Hıyar Mozaik Virüsü",
            description: "Hıyar mozaik virüsü (Cucumber Mosaic Virus), Cucumber mosaic virus (CMV) olarak bilinen bir patojenin neden olduğu viral bir hastalıktır. Hastalık yapraklarda mozaik desenleri, deformasyon ve büyüme geriliği gibi belirtilerle kendini gösterir. Virüs, genellikle böcekler (özellikle yaprak bitleri) tarafından taşınır ve enfekte tohumlarla da yayılabilir. Şiddetli enfeksiyonlar, bitki büyümesini durdurabilir ve meyve üretimini önemli ölçüde azaltabilir.",
          },
          mottle: {
            title: "Hıyar Yeşil Mozaik Virüsü",
            description: "Hıyar yeşil mozaik virüsü (Cucumber Green Mottle Mosaic Virus), yapraklarda açık yeşil ve koyu yeşil mozaik desenleri ile kendini gösterir. Virüs, meyvelerde renk bozulmasına ve deformasyona neden olabilir. Çoğunlukla enfekte tohumlar veya kontamine sulama suyu ile yayılır. Şiddetli enfeksiyonlar bitkinin genel zayıflamasına ve ürün kalitesinin düşmesine yol açar.",
          },
          bacterialDiseases: "Bakteriyel Hastalıklar",
          fungalDiseases: "Mantar Hastalıkları",
          viralDiseases: "Virüs Hastalıkları",
          photoData: {
            title: "Fotoğraflar",
            description: "Bu dosya bitki fotoğraflarını içerir.",
          },
          sensorData: {
            title: "Sensör Verileri",
            description: "Bu dosya sensör verilerini csv olarak içerir",
          },
          whatWeDo: "Ne Yapıyoruz?",
          projectSummary:"Bitki hastalıklarının güvenilir ve zamanında tanımlanması, modern tarımın önemli zorluklarından biridir. Geleneksel yöntemler genellikle geç aşamalardaki semptomlara dayanır. Yapay zeka, görüntü işleme ve makine öğrenmesi ile hastalık belirtilerini erken aşamalarda tespit edebilir, sensörler ve IoT cihazlarıyla gerçek zamanlı veriler sunarak çiftçilerin proaktif önlem almasına olanak sağlar. Bu yöntemler verimliliği artırırken çevresel etkileri minimize eder.\n\nBu proje ile IoT cihazlar ve sensörler yardımıyla toplanan veriler ile erken teşhis ve hastalık yönetimi konusunda eğitilen yapay zeka modellerinin geliştirilmesi ve uygunlanması üzerine araştırmalar yapılmaktadır.",
          welcome: "Sensör Verileri",
          authors: "Yazarlar",
          mainPage: "Ana Sayfa",
          ourTeam: "Takımımız",
          publications: "Yayınlarımız",
          diseases: "Hastalıklar",
          about: "Hakkında",
          data: "Veri",
          university: "Eskişehir Osmangazi Üniversitesi",
          avgSalt: "Ortalama Tuz",
          avgNitrogen: "Ortalama Azot",
          avgHumidity: "Ortalama Nem",
          aboutUs: "Hakkımızda",
          learnMore: "Daha Fazlasını Öğrenin",
          viewPublication: "Yayını Göster",
          recentPublication: "Son Yayınlar",
          photos: "Fotoğraflar",
          meet: "Takımımızla Tanışın",
          projectManager: "Proje Yürütücüsü",
          researchers: "Araştırmacılar",
          scholarships: "Bursiyerler",
          linkedin: "Linkedin Profili",
          researcher: "Araştırmacı",
          scholarship: "Bursiyer",
          voluntary: "Gönüllü Katılımcı",
          undergraduateStudent: "Lisans Öğrencisi",
          graduateStudent: "Yüksek Lisans Öğrencisi",
          prof: "Profesör Doktor",
          assistantProf: "Doktor Öğretim Üyesi",
          associateProf: "Doçent Doktor",
          agriculturalEng: "Ziraat Fakültesi",
          computerEng:"Bilgisayar Mühendisliği Bölümü",
          electricalEng: "Elektrik Elektronik Mühendisliği Bölümü",
          publication: "Yayınlar",
          aboutProject: "Projemiz Hakkında",
          projectTitle:"Proje Adı",
          project: "Yapay Zeka ile Sera Koşullarındaki Hıyar Yetiştiriciliğinde Bazı Hastalık ve Zararlıların Erken Dönemde Tanısı ve İzlenmesi",
          p1:"Bitki hastalıklarının güvenilir ve zamanında tanımlanması, modern tarımın karşılaştığı en önemli zorluklardan biridir. Geleneksel yöntemler, genellikle gözle görülebilir semptomların manuel olarak gözlemlenmesine dayanır. Ancak bu semptomlar, enfeksiyonun genellikle orta veya geç aşamalarında ortaya çıktığından, hastalık bitkide yayılmadan veya verim kaybı yaşanmadan önce müdahale edilmesi zor hale gelir. Bu nedenle, yayılma riskini azaltmak ve ürün kayıplarını önlemek için daha erken ve daha hassas tespit yöntemlerine ihtiyaç vardır.",
          p2:"Yapay zeka teknolojileri, bu noktada devreye girerek tarımda devrim yaratma potansiyeline sahiptir. Görüntü işleme ve makine öğrenmesi algoritmaları, bitkilerin çeşitli görüntülerini analiz ederek, gözle görülür semptomlar ortaya çıkmadan önce bile hastalık belirtilerini tespit edebilir. Bu algoritmalar, geniş veri kümeleri üzerinde eğitilerek, hastalıkların farklı aşamalarını, iklim koşullarını ve bitki türlerini göz önünde bulundurarak yüksek doğruluk oranlarıyla tahminler yapabilir.",
          p3:"Ayrıca, yapay zeka destekli sensörler ve IoT cihazları, tarlalardan toplanan gerçek zamanlı verileri analiz ederek anlık uyarılar verebilir. Böylece çiftçiler, tarlalarındaki her bitkiyi sürekli olarak izleyebilir ve hastalıklara karşı proaktif bir şekilde önlem alabilir. Bu teknoloji, sadece hastalık tespitini hızlandırmakla kalmaz, aynı zamanda pestisit kullanımını optimize ederek çevresel etkileri de minimize edebilir.",
          p4:"Sonuç olarak, yapay zeka ile entegre edilmiş modern tarım yöntemleri, hastalıkların erken teşhisi ve yönetimi konusunda büyük bir potansiyele sahiptir. Bu teknolojiler, verimliliği artırırken, sürdürülebilir tarım uygulamalarının yaygınlaşmasına da katkı sağlayabilir.",
          p5:"Bu proje ile IoT cihazlar ve sensörler yardımıyla toplanan veriler ile erken teşhis ve hastalık yönetimi konusunda eğitilen yapay zeka modellerinin geliştirilmesi ve uygunlanması üzerine araştırmalar yapılmaktadır.",
          note:"**Bu proje kapsamında yapılan çalışmalar Türkiye Bilimsel ve Teknolojik Araştırma Kurumu (TÜBİTAK) tarafından 123O928 numaralı proje ile desteklenmiştir. Projeye verdiği destekten ötürü TÜBİTAK’a teşekkürlerimizi sunarız.**",
          startDate: "Başlangıç Tarihi",
          endDate: "Bitiş Tarihi",
          update: "Güncelle",
          sector1Data: "Sektör 1 Verileri",
          sector1NoData: "Sektör 1 için girdiğiniz tarih aralığında veri bulunamadı.",
          sector2Data: "Sektör 2 Verileri",
          sector2NoData: "Sektör 2 için girdiğiniz tarih aralığında veri bulunamadı.",
          temperature: "Sıcaklık",
          light: "Işık x10",
          humidity: "Nem"
        },
      },
    },
    fallbackLng: 'en', // Dil algılanamazsa varsayılan dil
    interpolation: {
      escapeValue: false, // React zaten XSS koruması sağlar
    },
  });

export default i18n;
