import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const DataPage = () => {
    const { t } = useTranslation();
    const [chartDataS1, setChartDataS1] = useState(null);
    const [chartDataS2, setChartDataS2] = useState(null);
    const [startDate, setStartDate] = useState(() => {
        const today = new Date();
        today.setMonth(today.getMonth() - 1);
        return today;
    });
    const [endDate, setEndDate] = useState(new Date());
    const [dataAvailableS1, setDataAvailableS1] = useState(false);
    const [dataAvailableS2, setDataAvailableS2] = useState(false);

    const fetchDailyAverages = async () => {
        try {
            const response = await axios.get(
                '/sensor-data/daily-averages-for-all-sensors', // Proxy ile sadece path kullanıyoruz
                {
                    params: {
                        startDate: startDate.toISOString().split('T')[0],
                        endDate: endDate.toISOString().split('T')[0],
                    },
                }
            );

            const { S1, S2 } = response.data;

            // S1 verileri
            const s1Labels = [...new Set(S1.map((item) => item.date))];
            if (s1Labels.length > 0) {
                setDataAvailableS1(true);
                setChartDataS1({
                    labels: s1Labels,
                    datasets: [
                        {
                            label: t('temperature'),
                            data: S1.filter((item) => item.sensor_id === 1).map((item) => item.averageValue),
                            borderColor: 'rgba(255, 99, 132, 0.7)',
                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                            tension: 0.4,
                        },
                        {
                            label: t('humidity'),
                            data: S1.filter((item) => item.sensor_id === 3).map((item) => item.averageValue),
                            borderColor: 'rgba(54, 162, 235, 0.7)',
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            tension: 0.4,
                        },
                        {
                            label: t('light'),
                            data: S1.filter((item) => item.sensor_id === 5).map((item) => item.averageValue/10),
                            borderColor: 'rgba(75, 192, 192, 0.7)',
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            tension: 0.4,
                        },
                    ],
                });
            } else {
                setDataAvailableS1(false);
                setChartDataS1(null);
            }

            // S2 verileri
            const s2Labels = [...new Set(S2.map((item) => item.date))];
            if (s2Labels.length > 0) {
                setDataAvailableS2(true);
                setChartDataS2({
                    labels: s2Labels,
                    datasets: [
                        {
                            label: t('temperature'),
                            data: S2.filter((item) => item.sensor_id === 2).map((item) => item.averageValue),
                            borderColor: 'rgba(255, 99, 132, 0.7)',
                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                            tension: 0.4,
                        },
                        {
                            label: t('humidity'),
                            data: S2.filter((item) => item.sensor_id === 4).map((item) => item.averageValue),
                            borderColor: 'rgba(54, 162, 235, 0.7)',
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            tension: 0.4,
                        },
                        {
                            label: t('light'),
                            data: S2.filter((item) => item.sensor_id === 6).map((item) => item.averageValue/10),
                            borderColor: 'rgba(75, 192, 192, 0.7)',
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            tension: 0.4,
                        },
                    ],
                });
            } else {
                setDataAvailableS2(false);
                setChartDataS2(null);
            }
        } catch (error) {
            console.error('API çağrısı sırasında hata oluştu:', error);
            setDataAvailableS1(false);
            setDataAvailableS2(false);
            setChartDataS1(null);
            setChartDataS2(null);
        }
    };

    useEffect(() => {
        fetchDailyAverages();
    }, [startDate, endDate]);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        fetchDailyAverages();
    };

    return (
        <div className="min-h-screen bg-gray-100 py-10">
            <h1 className="text-3xl font-bold text-green-600 text-center mb-10">{t('welcome')}</h1>

            {/* Form */}
            <form className="mb-8 max-w-xl mx-auto" onSubmit={handleFormSubmit}>
                <div className="flex justify-between items-center gap-4">
                    <div>
                        <label className="block font-medium mb-2">{t('startDate')}</label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                            className="p-2 border rounded w-full"
                        />
                    </div>
                    <div>
                        <label className="block font-medium mb-2">{t('endDate')}</label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="yyyy-MM-dd"
                            className="p-2 border rounded w-full"
                        />
                    </div>
                </div>
                <button
                    type="submit"
                    className="mt-4 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
                >
                    {t('update')}
                </button>
            </form>

            {/* Grafikler */}
            <div className="mx-auto max-w-4xl space-y-10">
                {dataAvailableS1 ? (
                    <div>
                        <h2 className="text-2xl font-bold text-center mb-5">{t('sector1Data')}</h2>
                        <Line
                            data={chartDataS1}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        position: 'top',
                                    },
                                },
                            }}
                        />
                    </div>
                ) : (
                    <div className="text-center text-gray-500">
                        <p>{t('sector1NoData')}</p>
                    </div>
                )}

                {dataAvailableS2 ? (
                    <div>
                        <h2 className="text-2xl font-bold text-center mb-5">{t('sector2Data')}</h2>
                        <Line
                            data={chartDataS2}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        position: 'top',
                                    },
                                },
                            }}
                        />
                    </div>
                ) : (
                    <div className="text-center text-gray-500">
                        <p>{t('sector2NoData')}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DataPage;
