import React from 'react';
import DiseaseCard from '../components/DiseaseCard';
import { useTranslation } from 'react-i18next';

const diseases = [
  {
    key: "downy",
    imageUrl: require('../assets/DiseasePhotos/DownyMildew.png'),
  },
  {
    key: "powdery",
    imageUrl: require('../assets/DiseasePhotos/PowderyMildew.jpg'),
    
  },
  {
    key: "wilt",
    imageUrl: require('../assets/DiseasePhotos/BacterialWilt.jpg'),
  },
  {
    key: "angularspot",
    imageUrl: require('../assets/DiseasePhotos/LeafSpot.jpg'),
    
  },
  {
    key: "mosaic",
    imageUrl: require('../assets/DiseasePhotos/MosaicVirus.jpg'),
  },
  {
    key: "mottle",
    imageUrl: require('../assets/DiseasePhotos/GreenMottle.jpg'),
    
  },
  // Add more diseases as needed
];

const DiseasePage = () => {
  
  const { t } = useTranslation();


  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-bold text-center mb-12">{t('diseases')}</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
        {diseases.map((disease, index) => (
          <DiseaseCard
            key={index}
            DiseaseKey = {disease.key}
            imageUrl={disease.imageUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default DiseasePage;
