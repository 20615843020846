import React from 'react';
import ContributorsCard from '../components/ContributorsCard';
import { useTranslation } from 'react-i18next';

const TeamPage = () => {
  const { t } = useTranslation();
  const contributors = {
    scholarshipStudents: [
      {
        name: "Elif Genç",
        role: t('scholarship'),
        title: t('graduateStudent'),
        department: t('computerEng'),
        linkedin: "https://www.linkedin.com/in/elif-gen%C3%A7-162044173/",
        image: require('../assets/TeamPhotos/ElifGenc.jpeg')
      },
      {
        name: "İrem Kavakpınar",
        role: t('scholarship'),
        title: t('undergraduateStudent'),
        department: t('computerEng'),
        linkedin: "https://www.linkedin.com/in/irem-kavakp%C4%B1nar-084719208/",
        image: require('../assets/TeamPhotos/IremKavakpinar.jpeg')
      },
      {
        name: "Buse Ercan",
        role: t('scholarship'),
        title: t('undergraduateStudent'),
        department: t('computerEng'),
        linkedin: "https://www.linkedin.com/in/buse-ercan-324baa228/",
        image: require('../assets/TeamPhotos/BuseErcan.jpeg')
      },
      {
        name: "Arda Çabuk",
        role: t('voluntary'),
        title: t('undergraduateStudent'),
        department: t('computerEng'),
        linkedin: "https://www.linkedin.com/in/arda-%C3%A7abuk-8355b2253/",
        image: require('../assets/TeamPhotos/ArdaCabuk.jpeg')
      },
      {
        name: "Harun Taha Kepenek",
        role: t('scholarship'),
        title: t('undergraduateStudent'),
        department: t('computerEng'),
        linkedin: "https://www.linkedin.com/in/harun-taha-kepenek-521722258/",
        image: require('../assets/TeamPhotos/HarunTaha.jpeg')
      },
      {
        name: "Cennet Göktepe",
        role: t('scholarship'),
        title: t('undergraduateStudent'),
        department: t('agriculturalEng'),
        linkedin: "https://www.linkedin.com/in/cennet-göktepe-a39736321/",
        image: require('../assets/TeamPhotos/CennetGoktepe.jpeg')
      }
    ],

    projectManager: [
      {
        name: "Kemal Özkan",
        role: t('projectManager'),
        title: t('prof'),
        department: t('computerEng'),
        linkedin: "https://www.linkedin.com/in/kemal-%C3%B6zkan-559621173/",
        image: require('../assets/TeamPhotos/KemalOzkan.png'),
      },

    ],

    researchers: [
      {
        name: "Yusuf Kartal",
        role: t('researcher'),
        title: t('assistantProf'),
        department: t('computerEng'),
        linkedin: "https://www.linkedin.com/in/yusuf-kartal-01/",
        image: require('../assets/TeamPhotos/YusufKartal.jpeg')
      },
      {
        name: "Nuray Çömlekçioğlu",
        role: t('researcher'),
        title: t('prof'),
        department: t('agriculturalEng'),
        linkedin: "https://www.linkedin.com/in/nuray-comlekcioglu-250b3861/",
        image: require('../assets/TeamPhotos/NurayComlekcioglu.png')
      },
      {
        name: "Filiz Ünal",
        role: t('researcher'),
        title: t('associateProf'),
        department: t('agriculturalEng'),
        image: require('../assets/TeamPhotos/FilizUnal.jpg')
      },
      {
        name: "Refik Bozbuğa",
        role: t('researcher'),
        title: t('associateProf'),
        department: t('agriculturalEng'),
        linkedin: "https://www.linkedin.com/in/refikbozbuga/",
        image: require('../assets/TeamPhotos/RefikBozbuga.png')
      },
      {
        name: "Erol Seke",
        role: t('researcher'),
        title: t('associateProf'),
        department: t('electricalEng'),
        linkedin: "https://www.linkedin.com/in/erol-seke-19b53628/",
        image: require('../assets/TeamPhotos/ErolSeke.jpeg')
      },
      {
        name: "Serkan Önder",
        role: t('researcher'),
        title: t('assistantProf'),
        department: t('agriculturalEng'),
        linkedin: "https://www.linkedin.com/in/dr-serkan-%C3%B6nder-002063b7/",
        image: require('../assets/TeamPhotos/SerkanOnder.jpg')
      },
      {
        name: "Doğancan Kaya",
        role: t('researcher'),
        title: t('assistantProf'),
        department: t('agriculturalEng'),
        linkedin: "https://www.linkedin.com/in/dogancan-kahya-6a836078",
        image: require('../assets/TeamPhotos/DogancanKahya.png')
      },
    ],
  };
  return (
    <>
      <div className="min-h-screen bg-gray-100 py-8">
        <div className="max-w-5xl mx-auto px-4">
          <h1 className="text-4xl font-bold text-center mb-12">
            {t('meet')}
          </h1>
          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-6 text-center">{t('projectManager')}</h2>
            <div className="flex justify-center">
              {contributors.projectManager.map((member, index) => (
                <ContributorsCard key={index} {...member} className="flex flex-col items-center text-center" />
              ))}
            </div>
          </section>
          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-6 text-center">{t('researchers')}</h2>
            <div className="flex justify-center flex-wrap gap-4">
              {contributors.researchers.map((member, index) => (
                <ContributorsCard key={index} {...member} className="flex flex-col items-center text-center" />
              ))}
            </div>
          </section>
          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-6 text-center">{t('scholarships')}</h2>
            <div className="flex justify-center flex-wrap gap-4">
              {contributors.scholarshipStudents.map((member, index) => (
                <ContributorsCard key={index} {...member} className="flex flex-col items-center text-center" />
              ))}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}


export default TeamPage;