function Footer() {
    return (
      <footer className="bg-green-100 text-gray-700 py-6 mt-8 shadow-inner">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-6">
          {/* Sol taraf: Üniversite bilgisi */}
          <div className="text-center md:text-left mb-4 md:mb-0">
            <p className="font-semibold text-lg">Eskişehir Osmangazi Üniversitesi</p>
            <p className="text-sm">Tüm hakları saklıdır &copy; 2024</p>
          </div>
  
          {/* Sağ taraf: Sorumlular ve sosyal medya */}
          <div className="text-center">
            <p className="text-sm">
              Web Sitesi Sorumluları: <span className="font-medium"><h1>Arda Çabuk | ardacabuk1@hotmail.com</h1> </span><span className="font-medium"><h1>Harun Taha Kepenek | haruntkepenek@gmail.com</h1></span>
            </p>
            {/* Sosyal medya simgeleri */}
            <div className="flex justify-center md:justify-end mt-2 space-x-4">
            </div>
          </div>
        </div>
      </footer>
    );
  }
  
  export default Footer;
  