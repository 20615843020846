import React from 'react';
import { useTranslation } from 'react-i18next';
import PhotoCard from '../components/PhotoCard';

const data = [

  {
    key: "Fungus",
    imageUrl: require('../assets/SamplePlantPhotos/fungus.jpg'),
    bilgi: "Bu bitki Fungus koleksiyonundan bir örnektir.",
  },

  {
    key: "Fungus Nematod",
    imageUrl: require('../assets/SamplePlantPhotos/fungus_nematod.jpg'),
    bilgi: "Bu bitki Fungus Nematod koleksiyonundan bir örnektir.",
  },
  {
    key: "Fungus Nematod Virüs",
    imageUrl: require('../assets/SamplePlantPhotos/fungus_nematod_virüs.jpg'), 
    bilgi: "Bu bitki Fungus Nematod Virüs koleksiyonundan bir örnektir.",
  },
  {
    key: "Fungus Virüs",
    imageUrl: require('../assets/SamplePlantPhotos/fungus_virüs.jpg'), 
    bilgi: "Bu bitki Fungus Virüs koleksiyonundan bir örnektir.",
  },
  {
    key: "Nematod",
    imageUrl: require('../assets/SamplePlantPhotos/nematod.jpg'),
    bilgi: "Bu bitki Nematod koleksiyonundan bir örnektir.",
  },
  {
    key: "Nematod Virüs",
    imageUrl: require('../assets/SamplePlantPhotos/nematod_virüs.jpg'),
    bilgi: "Bu bitki Nematod Virüs koleksiyonundan bir örnektir.",
  },
  {
    key: "Sağlıklı Bitki",
    imageUrl: require('../assets/SamplePlantPhotos/saglıklı.jpg'),
    bilgi: "Bu bitki Sağlıklı Bitki koleksiyonundan bir örnektir.",
  },
  {
    key: "Virüs",
    imageUrl: require('../assets/SamplePlantPhotos/virüs.jpg'),
    bilgi: "Bu bitki Virüs koleksiyonundan bir örnektir.",
  },
];

const DownloadPage = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="max-w-5xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-center mb-12">{t('data')}</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {data.map((item, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-lg">
              <PhotoCard imageUrl={item.imageUrl} />
              <p className="mt-4 text-gray-700">{item.bilgi}</p>
            </div>
          ))}
        </div>
      </div>
      <footer className="bg-gray-100 text-gray-800 py-6 mt-8">
        <div className="text-center">
          <p>
          Veri seti taleplerinizi{" "}
            <a
              href="kozkan@ogu.edu.tr"
              className="text-green-600 hover:text-green-800 font-semibold"
            >
              kozkan@ogu.edu.tr
            </a>
            {" "}adresine iletebilirsiniz.
          </p>
        </div>
      </footer>
    </div>

  );
};

export default DownloadPage;
