import React from 'react';

const VideoPlayer = ({ videoUrl, className }) => {
  return (
    <div className={`relative w-full max-w-[850px] h-[500px] mx-auto ${className}`}>
      <iframe
        src={`${videoUrl}?autoplay=1&mute=1&loop=1&playlist=NleaSZpHses&controls=0&showinfo=0&modestbranding=1&rel=0`}
        allow="autoplay; encrypted-media"
        allowFullScreen
        className="absolute inset-0 top-0 left-0 w-full h-full object-cover rounded-lg shadow-lg border border-gray-300"
      ></iframe>
    </div>
  );
};

export default VideoPlayer;
