import React from 'react';
import { useTranslation } from 'react-i18next';

const DiseaseCard = ({ DiseaseKey, imageUrl }) => {
  const { t } = useTranslation();
  const title = t(`${DiseaseKey}.title`);
  const description = t(`${DiseaseKey}.description`);

  return (
    <div className="flex flex-col items-center gap-6 bg-[#e8f5e9] p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300">
      {/* Başlık ve açıklama */}
      <div className="flex flex-col gap-4">
        <p className="text-[#2e7d32] text-xl font-bold leading-tight">{title}</p>
        <p className="text-[#4a7f4d] text-base font-normal leading-normal">{description}</p>
      </div>
      {/* Resim */}
      <div className="w-full sm:w-64 h-auto">
        <img
          src={imageUrl}
          alt={title}
          className="w-full h-auto rounded-xl object-cover"
        />
      </div>
    </div>
  );
};

export default DiseaseCard;
