import React from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Header() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const navLinks = [
    { text: t('mainPage'), path: '/' },
    { text: t('ourTeam'), path: '/team' },
    { text: t('publications'), path: '/publications' },
    { text: t('diseases'), path: '/diseases' },
    { text: t('about'), path: '/about' },
    { text: t('data'), path: '/data' },
    { text: t('download'), path: '/download' },
  ];

  return (
    <header className="flex items-center justify-between whitespace-nowrap border-b border-solid border-b-[#e8f5e9] px-10 py-3">
      <div className="flex items-center gap-4 text-[#121714]">
        <Link to='/'>
          <div
            className="bg-center bg-no-repeat aspect-square bg-cover rounded-full size-10"
            style={{ backgroundImage: 'url("https://www.ogu.edu.tr/Content/2019/img/site-logo-outlined.png")' }}
          ></div>
        </Link>
        <Link to='/'>
          <h2 className="text-[#121714] text-lg font-bold leading-tight tracking-[-0.015em]">{t('university')}</h2>
        </Link>
        {/* Dil değiştirme butonları */}
        <div className="flex items-center gap-2 ml-4">
          <button onClick={() => changeLanguage('en')} className="text-[#121714] text-sm font-medium leading-normal transition-all duration-300 ease-in-out hover:text-[#36c26c]">
            EN
          </button>
          <button onClick={() => changeLanguage('tr')} className="text-[#121714] text-sm font-medium leading-normal transition-all duration-300 ease-in-out hover:text-[#36c26c]">
            TR
          </button>
        </div>
      </div>
      <div className="flex flex-1 justify-end items-center gap-4">
        {navLinks.map((link, index) => (
          <React.Fragment key={index}>
            {index !== 0 && (
              <div className="h-4 w-[1px] bg-[#36c26c]"></div>
            )}
            <Link to={link.path} className="relative text-[#121714] text-sm font-medium leading-normal transition-all duration-300 ease-in-out hover:text-[#36c26c] after:content-[''] after:absolute after:left-0 after:bottom-[-2px] after:h-[2px] after:w-full after:scale-x-0 after:bg-[#36c26c] after:transition-transform after:duration-300 after:ease-in-out hover:after:scale-x-100">
              {t(link.text)}
            </Link>
          </React.Fragment>
        ))}
      </div>
    </header>
  );
}

export default Header;
