import React from 'react';
import greenhouseImage from '../assets/GreenhousePhotos/Greenhouse5.jpg'; // Import the image
import { useTranslation } from 'react-i18next';

const AboutPage = () => {

  const { t } = useTranslation();

  return (
    <div className="flex flex-col lg:flex-row items-center justify-between p-8 bg-gray-100 min-h-[calc(100vh-65px)]">
      <div className="lg:w-1/2 w-full mb-8 lg:mb-0 flex flex-col justify-center">
        <h1 className="text-4xl font-bold text-green-700 mb-4">{t('aboutProject')}</h1>
        <p className="text-lg text-gray-700 font-semibold mb-4">
        <span className="text-green-600">{t('projectTitle')}:</span>{t('project')}
        </p>
        <p className="text-lg text-gray-700 mb-4">
          {t('p1')}
        </p>
        <p className="text-lg text-gray-700 mb-4">
        {t('p2')}
        </p>
        <p className="text-lg text-gray-700 mb-4">
        {t('p3')}
        </p>
        <p className="text-lg text-gray-700 mb-4">
        {t('p4')}
        </p>
        <p className="text-lg text-gray-700">
        {t('p5')}
        </p>
        <div className="mt-8 text-gray-500 text-sm">
          <p>
          {t('note')}
          </p>
        </div>
      </div>
      <div className="lg:w-1/2 w-full flex justify-center">
        <img src={greenhouseImage} alt="Greenhouse" className="rounded-lg shadow-lg w-3/4 h-auto max-w-full" />
      </div>
    </div>
  );
};

export default AboutPage;
