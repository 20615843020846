import { Routes,Route } from 'react-router-dom';
import Header from './components/Header.js';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import MainPage from './pages/MainPage.js';
import TeamPage from './pages/TeamPage.js'
import PublicationsPage from './pages/PublicationPage.js';
import AboutPage from './pages/AboutPage.js';
import DiseasePage from './pages/DiseasePage.js';
import DataPage from './pages/DataPage.js';
import './i18n';
import DownloadPage from './pages/DownloadPage.js';
import Footer from './components/Footer.js';


function App() {
  return (
    <I18nextProvider i18n={i18n}>
    <div className="font-sans bg-gray-100">
      <Header/>
      <Routes>
        <Route path='/' element={<MainPage/>}></Route>
        <Route path='/team' element={<TeamPage/>}></Route>
        <Route path='/publications' element={<PublicationsPage/>}></Route>
        <Route path='/about' element={<AboutPage/>}></Route>
        <Route path='/diseases' element={<DiseasePage/>}></Route>
        <Route path='/data' element={<DataPage/>}></Route>
        <Route path='/download' element={<DownloadPage/>}></Route>
      </Routes>
      <Footer/>
    </div>
    </I18nextProvider>
  );
}

export default App;
