import React from 'react';
import PublicationCard from '../components/PublicationCard';
import { useTranslation } from 'react-i18next';

const publications = [
  {
    key: "publication1",
    imageUrl: require('../assets/PublicationPhotos/publ1.jpeg'),
    link: "https://dergipark.org.tr/tr/journal/1878/article/1529025"
  }
  // Add more publications here
];

const PublicationsPage = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="max-w-5xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-center mb-12">{t('publications')}</h1>
        <div className="grid grid-cols-1 gap-8">
          {publications.map((pub, index) => (
            <PublicationCard
              key={index}
              publicationKey={pub.key}
              imageUrl={pub.imageUrl}
              link={pub.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PublicationsPage;
