import React from 'react';
import { FaLinkedin } from 'react-icons/fa'; // LinkedIn logo
import { useTranslation } from 'react-i18next';

const ContributorsCard = ({ name, role, title,department, linkedin, image }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center bg-[#e8f5e9] p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 max-w-xs w-full" >
      <div
        className="bg-center bg-cover bg-no-repeat rounded-full h-32 w-32 mb-4"
        style={{ backgroundImage: `url(${image})` }}
      />
      <p className="text-sm text-[#4a7f4d] mb-4 text-center">{title}</p>
      <h3 className="text-xl font-semibold text-[#2e7d32] mb-1 text-center">{name}</h3>
      <p className="text-md text-[#4a7f4d] mb-2 text-center">{role}</p>
      <p className="text-sm text-[#4a7f4d] mb-4 text-center">{department}</p>
      <a
        href={linkedin}
        target="_blank"
        rel="noopener noreferrer"
        className="text-[#2e7d32] hover:text-[#1b5e20] flex items-center space-x-2"
      >
        <FaLinkedin size={24} />
        <span>{t('linkedin')}</span>
      </a>
    </div >
  );
}

export default ContributorsCard;
